#Landing {
  background-color: var(--colour-primary); /* Use CSS variable */
  /* height: 100vh; */
  /* width: 100vw; */

  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  color: var(--colour-inverse-primary);
  position: relative;

  /* overflow-x: hidden; */
}

/* Gradient effect on text with animation */
.text-gradient {
  background: linear-gradient(90deg, #21d4fd, #b721ff, #86a8e7, #8417dd);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-flow 10s ease infinite;
}

@keyframes gradient-flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#Landing .extension-example{

  
  position: relative;
  width: 100%;
  z-index: 0;
}

#Landing .extension-example .example{
  /* --distance: 100px; */
  --distance: 10vw;
  --rotation: 25deg;
  /* --down: -200px; */
  --down: 0px;

  position: absolute;
  /* height: 250px; */
  width: 15vw;

  background-color: black;
  
  padding: 5px;
  border: 2px solid white;
  border-radius: 20px;

  display: flex;
  justify-content:center;
  align-items: center;
}

#Landing .extension-example .example img{
  height: 100%;
  width: 100%;

  border-radius: 15px;
}

#Landing .extension-example .example#ext0{
  /* transform: rotate(var(--rotation)); */
  right: var(--distance);
  /* top: var(--down) */
}

#Landing .extension-example .example#ext1{
  /* transform: rotate(calc(-1 * var(--rotation))); */
  left: var(--distance);
  /* top: var(--down) */
}


.landing-text {
  width: 60vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 100px 0; /* Adjusted margin for uniform spacing */

  z-index: 100;
}

/* Larger font size for the h1 element */
.landing-text h1 {
  /* font-size: 4rem; */
  font-size: calc(2vw + 4vh);
  font-weight: 700;
  text-align: center;
  
  margin: 0;
  margin-bottom: 20px; /* Adjusted margin for uniform spacing */
}

.landing-text p {
  /* font-size: 1.5rem; */
  font-size: calc(1vw + 1.5vh);
  font-weight: normal;
  text-align: center;
  /* line-height: rem; */
  margin: 0;
  width: 80%;
}

.landing-text button {
  font-size: 1.5rem;
  padding: 35px 45px;  /* Increase horizontal padding */
  /* min-width: 300px;   */
  max-width: 80vw;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  margin-top: 20px;
  
  outline: none;
  
  border-radius: 10px;
  border: 2px solid transparent;
  background: #BB86FC;
  color: black;
  
  cursor: pointer;
  transition: all 0.3s ease;
}

.landing-text button img{
  height: 30px;
  margin-right: 10px;
}

.landing-text button{
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.landing-text button:hover {
  border: 2px solid #BB86FC;
  background: black;
  color: #BB86FC;
  img {
    filter: brightness(0) saturate(100%) invert(67%) sepia(29%) saturate(1825%) hue-rotate(212deg) brightness(97%) contrast(98%);
  }
}

#Landing .rating{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  margin-top: 20px; /* Adjusted margin for uniform spacing */

  opacity: 0.8;
}

#Landing .rating p{
  font-size: 1em;
  color: white;
}

#Landing .rating img{
  height: 15px;
}

#Landing .snippet {
  overflow: hidden;
  border-radius: 20px;
  border: 2px solid white;
  padding: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  
}

/* #Landing .snippet img{ */
#Landing .snippet video{
  /* border-radius: 5px; */
  border-radius: 15px;
  width: 70vw;
}

/* Styles for positioning links side by side in the bottom-left corner */
.bottom-left {
  position: relative;

  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 10px; /* Space between the links */
}

.privacy-link {
  color: purple; /* Set the text color to purple */
  font-size: 0.75rem; /* Smaller font size */
  text-decoration: none;
}

.privacy-link:hover {
  text-decoration: underline; /* Add underline on hover */
}

.social-links {
  display: flex;
  gap: 25px;
  margin-top: 55px; /* Adjusted margin for uniform spacing */
  justify-content: center;
}

.social-links a {
  width: 34px;
  height: 34px;
  border-radius: 15%;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.1);
}

.social-links img {
  width: 40px;
  height: 40px;
}


.sticky-social a img[src*="instagram"] {
  width: 38px;
  height: 38px;
}

.sticky-social a img[src*="x"] {
  width: 32px;
  height: 32px;
}


.sticky-social a img[src*="linkedin"] {
  width: 28px; 
  height: 28px;
}

.sticky-social a img[src*="tiktok"] {
  width: 28px;
  height: 28px;
}

.landing-text a {
  text-decoration: none;  /* Remove underline from the Link component */
}

.sticky-social {
  position: fixed;
  bottom: 5px;
  right: 5px;
  display: flex;
  gap: 15px;
  background: rgba(0, 0, 0, 0);
  padding: 10px;
  border-radius: 10px;
  z-index: 1000;
}

.sticky-social a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
}

.sticky-social a:hover {
  transform: scale(1.1) translateY(-3px);
}

.sticky-social img {
  width: 30px;
  height: 30px;
}




