/* Container for the Terms of Service page */
#terms-of-service {
  color: #ffffff !important; /* Ensure all text is white */
  padding: 20px;
  background-color: #121212 !important; /* Dark background color */
  box-sizing: border-box;
}

/* Main container for text */
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Main title styling */
.title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  color: #bb86fc !important; /* Purple color for the title */
}

/* Subtitle styling */
.sub-title {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
  color: #bb86fc !important; /* Purple color for subtitle */
}

/* Section title styling */
.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  border-bottom: 2px solid #bb86fc;
  padding-bottom: 0.25rem;
  color: #bb86fc !important; /* Purple color for section titles */
}

/* Paragraph styling */
#terms-of-service p {
  line-height: 1.6;
  margin-bottom: 1.25rem;
  margin-left: 10px;
  margin-right: 10px;
  color: #f1f1f1 !important; /* Off-white color for paragraphs */
}

/* Link styling inside paragraphs */
#terms-of-service p a.contact-link {
  color: #03dac6 !important; /* Teal color for links */
  text-decoration: none;
}

#terms-of-service p a.contact-link:hover {
  text-decoration: underline !important; /* Underline links on hover */
}

/* Styling for ordered and unordered lists */
#terms-of-service ul,
#terms-of-service ol {
  margin-left: 20px;
  margin-right: 20px;
  color: #f1f1f1 !important; /* Use the same color as paragraphs */
}

#terms-of-service li {
  margin-bottom: 0.75rem; /* Spacing between list items */
}

/* Responsive styling */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .sub-title {
    font-size: 1.25rem;
  }

  .section-title {
    font-size: 1.15rem;
  }

  .container {
    padding: 0 15px;
  }
}
