.companies-wrapper {
  min-height: 100vh;
  background-color: var(--colour-primary);
  padding-top: 80px; /* Add space for navbar */
}

.companies-container {
  max-width: 1200px;
  margin: 0 auto;
  height: calc(100vh - 80px);
  overflow-y: auto;
  /* padding: 0 2rem; Move horizontal padding here */
  /* scrollbar-width: thin;
  scrollbar-color: var(--colour-tertiary) var(--colour-primary); */
}

.companies-container .companies-title {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.companies-container .controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.companies-container .intro-text {
  color: var(--colour-inverse-primary);
  margin-bottom: 2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  padding-top: 2rem; /* Move top padding here */
}

.companies-container .intro-text h2 {
  font-size: 2rem;
  margin-bottom: 1.2rem;
  color: var(--colour-contrast-primary);
  font-weight: 500;
}

.companies-container .intro-description {
  font-size: 1.1rem;
  line-height: 1.6;
  opacity: 0.9;
  padding: 0 1rem;
}

.companies-container .intro-section {
  margin-bottom: 1.5rem;
}

.companies-container .intro-section h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--colour-inverse-primary);
  font-weight: 500;
  opacity: 0.9;
}

.companies-container .intro-section p {
  font-size: 1rem;
  opacity: 0.8;
  line-height: 1.6;
}

.companies-container .intro-text a {
  color: var(--colour-contrast-primary);
  cursor: pointer;
  text-decoration: underline;
}

.companies-container .search-bar {
  /* position: sticky; */
  top: 0;
  /* background-color: var(--colour-primary); */
  /* padding: 1rem 0; */
  z-index: 1;
  margin: 1rem 0; 
}

.companies-container .search-input {
  width: 300px;
  padding: 8px 12px;
  border: 1px solid var(--colour-tertiary);
  border-radius: 4px;
  font-size: 14px;
  color: var(--colour-inverse-primary);
  background-color: var(--colour-secondary);
}

.companies-container .sort-select {
  padding: 12px 16px;
  border: 1px solid var(--colour-tertiary);
  border-radius: 8px;
  background: var(--colour-inverse-primary);
  color: var(--colour-primary);
  cursor: pointer;
}

.companies-container .results-container {
  background: var(--colour-secondary);
  border: 1px solid var(--colour-tertiary);
  border-radius: 8px;
}

.companies-container .company-item {
  padding: 1rem;
  border-bottom: 1px solid var(--colour-tertiary);
  cursor: pointer;
}

.companies-container .company-item:last-child {
  border-bottom: none;
}

.companies-container .company-item:hover {
  background-color: rgba(187, 134, 252, 0.05); /* Very subtle purple tint on hover */
}

.companies-container .company-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.companies-container .company-name {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: var(--colour-inverse-primary);
}

.companies-container .expand-arrow {
  color: var(--colour-tertiary);
  transition: transform 0.2s ease;
  font-size: 12px;
}

.companies-container .expand-arrow.expanded {
  transform: rotate(180deg);
}

.companies-container .formats-list {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 1rem;
}

.companies-container .format-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0;
}

.companies-container .format-pattern {
  font-family: monospace;
  color: var(--colour-inverse-primary);
  font-size: 0.9rem;
}

.companies-container .loading {
  text-align: center;
  padding: 2rem;
  color: var(--colour-inverse-primary);
}

@media (max-width: 768px) {
  .companies-container .controls-container {
    flex-direction: column;
  }
  
  .companies-container .search-input {
    width: 100%;
  }
  
  .companies-container .formats-list {
    padding-left: 0;
  }
}

.companies-container .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.companies-container .modal-content {
  /* background: var(--colour-secondary); */
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  color: var(--colour-inverse-primary);
}

.companies-container .modal-content h2 {
  margin-bottom: 1rem;
  color: var(--colour-inverse-primary);
}

.companies-container .modal-content a {
  color: var(--colour-contrast-primary);
}

.companies-container .modal-close {
  margin-top: 1rem;
  padding: 8px 16px;
  background: var(--colour-contrast-primary);
  border: none;
  border-radius: 4px;
  color: var(--colour-inverse-primary);
  cursor: pointer;
}

.companies-container .modal-close:hover {
  opacity: 0.9;
}

/* Add these rules to handle Companies component in Home view */
.companies-container .companies-wrapper.in-home {
  padding-top: 0;
  min-height: unset;
  height: 100%;
  background-color: transparent;
}

.companies-container .companies-wrapper.in-home .companies-container {
  height: 100%;
  padding: 0;
  max-width: unset;
  overflow-y: auto;
}

/* Ensure search bar stays visible */
.companies-container .companies-wrapper.in-home .search-bar {
  /* position: sticky; */
  top: 0;
  /* background-color: var(--colour-secondary);
  padding: 1rem 0; */
  z-index: 1;
}

.companies-container .highlight-link {
  color: var(--colour-contrast-primary);
  cursor: pointer;
  text-decoration: none;
  position: relative;
  padding-bottom: 2px;
}

.companies-container .highlight-link:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--colour-contrast-primary);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.companies-container .highlight-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Adjust spacing when in home view */
.companies-container .companies-wrapper.in-home .intro-text {
  padding: 0 1rem;
}

/* Make text more compact in home view */
.companies-container .companies-wrapper.in-home .intro-section {
  margin-bottom: 1rem;
}

.companies-container .companies-wrapper.in-home .intro-text h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

/* Webkit scrollbar styling for container */
.companies-container::-webkit-scrollbar {
  width: 8px;
}

.companies-container::-webkit-scrollbar-track {
  background: var(--colour-primary);
}

.companies-container::-webkit-scrollbar-thumb {
  background-color: var(--colour-tertiary);
  border-radius: 4px;
}

/* Remove the old scrollbar styles */
.companies-wrapper::-webkit-scrollbar,
.companies-wrapper::-webkit-scrollbar-track,
.companies-wrapper::-webkit-scrollbar-thumb {
  display: none;
}

/* Remove default scrollbar from body when on companies page */
body:has(.companies-wrapper:not(.in-home)) {
  overflow: hidden;
}
