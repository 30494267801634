.result-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #ffffff;
  opacity: 0.6;
  transition: 0.4s ease;
  overflow: hidden;
}

.result-entry:hover {
  opacity: 1;
}

.result-entry .name {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.result-entry .name:hover {
  text-decoration: underline;
}

.result-entry .profile-img {
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 30px;
  margin-right: 10px;
}

.result-entry .company {
  color: #bb86fc;
  font-size: 0.9rem;
  font-weight: 600;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
  transition: color 0.2s;
}

.remove-button:hover {
  color: #ff4444;
} 