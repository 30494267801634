.file-mask{
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-mask .mask{
    position: absolute;
    height: 500px;
    width: 700px;
    height: calc(100% - 50px);
    width: calc(100% - 50px);
    
    /* border: 2px dotted rgba(255, 255, 255, 0.467); */
    border: 2px dotted white;
    border-radius: 10px;
    /* background-color: aliceblue; */
    
    z-index: 10;
    pointer-events: none;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    opacity: 0.4;
}

.file-mask .mask img{
    height: 20%;
}

.file-mask .mask p{
    margin-top: 20px;
    font-size: 20px;
}

.file-mask label{
    height: 500px;
    width: 100%;
    opacity: 0;
}