#sending-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding: 20px; */
  /* background-color: #181818; */
  background-color: transparent;
  color: #fff;
}

/* Add styles for disabled state */
#sending-page.sending {
  pointer-events: none;
  opacity: 0.7;
}

/* Sending overlay */
.sending-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sending-content {
  background: #292929;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  color: white;
  max-width: 400px;
}

.sending-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.sending-content h2 {
  margin-bottom: 15px;
  font-size: 24px;
}

.sending-content p {
  color: #ccc;
  font-size: 16px;
  line-height: 1.4;
}

.send-emails-portion-container {
  position: relative;
  display: flex;

  height: calc(100% - 80px);
  width: 100%;
  gap: 20px;
}

.send-emails-container {
  height: calc(100% - 40px);
  background-color: transparent;

  border: 1px solid rgba(255, 255, 255, 0.386);
}

.recipient-container {
}

.selected-recipients-list {
  position: relative;
  /* background-color: #292929; */
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  background-color: transparent;
  height: calc(100% - 40px);
  width: 30%;
  border: 1px solid rgba(255, 255, 255, 0.386);
  /* box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.386); */
  border-radius: 10px;
}

.send-btn {
  background-color: #000;
  position: absolute;
  outline: none;
  border: none;
  aspect-ratio: 1/1;
  height: 50px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px #000;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.1s ease;
}

.send-btn:hover {
  transform: scale(1.1) translateY(-5px);
  box-shadow: 0px 0px 10px 0px #000;
}

.send-btn img {
  width: 50%;
  filter: invert(1);
  pointer-events: none;
}

.result-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #ffffff;
  opacity: 0.6;
  transition: 0.4s ease;
  overflow: hidden;
}

.result-entry:hover {
  opacity: 1;
}

.result-entry .name {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.result-entry .name:hover {
  text-decoration: underline;
}

.result-entry .profile-img {
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 30px;
  margin-right: 10px;
}

.result-entry #company {
  color: #bb86fc;
  font-size: 0.9rem;
  font-weight: 600;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
  width: 100%;
}

.success-message,
.error-message {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  min-width: 300px;
  max-width: 500px;
  background: #292929;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: rgba(244, 67, 54, 0.1);
}

.message-title {
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
}

.close-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  cursor: pointer;
  padding: 0 4px;
  line-height: 1;
  transition: all 0.2s;
}

.close-button:hover {
  color: white;
  transform: scale(1.1);
}

.message-body {
  padding: 16px;
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
  background: #292929;
}

.success-message {
  border: 2px solid #4CAF50;
}

.error-message {
  border: 2px solid #f44336;
  background: #292929;
}

.success-message .message-header {
  background: rgba(76, 175, 80, 0.1);
}

.error-message .message-header {
  background: rgba(244, 67, 54, 0.15);
  border-bottom: 1px solid rgba(244, 67, 54, 0.2);
}

.success-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.success-title {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.success-instruction {
  font-size: 0.9em;
  color: #e0e0e0;
}

/* Loading state styles */
.send-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.send-button.loading {
  cursor: not-allowed;
  opacity: 0.7;
}

.loading-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.fade-out {
  animation: fadeOut 0.5s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
}

.remove-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
  transition: color 0.2s;
}

.remove-button:hover {
  color: #ff4444;
}

.range-selector {
  margin: 15px 0;
  padding: 15px;
  background-color: var(--colour-secondary);
  border-radius: 10px;
  color: var(--colour-inverse-primary);
}

.range-inputs {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.range-inputs label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9em;
  color: var(--colour-inverse-primary);
}

.range-inputs input {
  width: 80px;
  padding: 5px;
  background-color: var(--colour-tertiary);
  border: 1px solid var(--colour-contrast-primary);
  border-radius: 4px;
  color: var(--colour-inverse-primary);
}

.range-inputs input:focus {
  outline: none;
  border-color: var(--colour-contrast-primary);
  box-shadow: 0 0 0 2px rgba(187, 134, 252, 0.2);
}

.range-info {
  font-size: 0.9em;
  color: var(--colour-contrast-primary);
  margin-top: 8px;
}

.sent-ranges-info {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--colour-tertiary);
}

.sent-ranges-info h4 {
  color: var(--colour-inverse-primary);
  margin-bottom: 8px;
  font-size: 0.9em;
}

.range-item {
  font-size: 0.9em;
  color: var(--colour-inverse-primary);
  opacity: 0.7;
  margin: 5px 0;
}

.warning-inline {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgba(255, 193, 7, 0.1);
  border: 1px solid #ffc107;
  color: #ffc107;
  font-size: 0.9rem;
  white-space: nowrap;
}

.warning-icon {
  font-size: 1.1rem;
}

.warning-text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  color: #e0e0e0;
}

.warning-range {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(187, 134, 252, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
}

.warning-date {
  color: #bb86fc;
  font-size: 0.9em;
}

.warning-separator {
  color: #404040;
  margin: 0 4px;
}

.warning-badge,
.warning-tooltip,
.warning-tooltip::before {
  display: none;
}

.previously-sent-container {
  background-color: #292929;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
}

.previously-sent-container h4 {
  color: #bb86fc;
  margin-bottom: 10px;
  font-size: 1em;
}

.sent-ranges {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.sent-range-item {
  background-color: #333;
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.sent-range-item .range {
  color: #fff;
  font-weight: 500;
}

.sent-range-item .date {
  color: #888;
  font-size: 0.9em;
}

.warning-inline.dataset-complete {
  background-color: rgba(76, 175, 80, 0.1);
  border: 1px solid #4CAF50;
}

.warning-inline.dataset-complete .warning-text {
  color: #4CAF50;
  font-weight: 500;
}

.subscription-required {
  max-width: 600px;
  margin: 100px auto;
  padding: 40px;
  text-align: center;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.subscription-required h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.subscription-required p {
  color: #666;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
}

.upgrade-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upgrade-button:hover {
  background: #0056b3;
}

.quota-banner {
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background: var(--colour-secondary);
  border: 1px solid rgba(187, 134, 252, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  max-width: 90%;
  width: max-content;
}

.quota-container {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
}

.quota-info {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  max-width: 50%;
  /* padding-right: 20px; */
}

.quota-status {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(187, 134, 252, 0.1);
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid rgba(187, 134, 252, 0.2);
}

.remaining-count {
  color: #fff;
  font-size: 0.9rem;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.batch-count {
  margin-left: 8px;
  color: var(--colour-inverse-secondary);
  opacity: 0.8;
  font-size: 0.9em;
}

.quota-warning {
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(255, 193, 7, 0.1);
  color: #ffd700;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid rgba(255, 193, 7, 0.2);
  backdrop-filter: blur(8px);
}

.warning-icon {
  color: #ffd700;
  font-size: 16px;
  flex-shrink: 0;
}

/* Remove the padding-top since we no longer have a full-width banner */
.sending-page-content {
  padding-top: 0;
}

.upgrade-link {
  text-decoration: underline;
  font-weight: 500;
  transition: opacity 0.2s ease;
  color: inherit;
}

.quota-warning:hover .upgrade-link {
  opacity: 0.8;
}

.empty-email-data{
  position: absolute;
  opacity: 0.5;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.ai-generate-button{
  margin-left: 10px;
}