.range-selector-sticky {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--colour-secondary);
  margin: -20px -20px 0;
  padding: 20px;
  border-bottom: 1px solid var(--colour-tertiary);
}

.range-selector-container {
  padding: 15px 0;
  border-bottom: 1px solid var(--colour-tertiary);
  margin-bottom: 15px;
}

.range-inputs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 12px;
}

.range-input-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.range-input-group label {
  font-size: 0.9em;
  color: var(--colour-inverse-primary);
  opacity: 0.9;
}

.range-input-group input {
  width: 70px;
  padding: 8px;
  background-color: var(--colour-tertiary);
  border: 1px solid var(--colour-contrast-primary);
  border-radius: 4px;
  color: var(--colour-inverse-primary);
  font-size: 0.9em;
  text-align: center;
}

.range-input-group input:focus {
  outline: none;
  border-color: var(--colour-contrast-primary);
  box-shadow: 0 0 0 2px rgba(187, 134, 252, 0.2);
}

.range-separator {
  color: var(--colour-inverse-primary);
  opacity: 0.7;
  font-size: 0.9em;
}

.range-info {
  text-align: center;
  color: var(--colour-contrast-primary);
  font-size: 0.9em;
  margin-top: 8px;
}

.sent-ranges-info {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--colour-tertiary);
}

.sent-ranges-info h4 {
  color: var(--colour-inverse-primary);
  font-size: 0.9em;
  margin-bottom: 8px;
  opacity: 0.9;
}

.sent-ranges-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.range-item {
  background-color: var(--colour-tertiary);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  color: var(--colour-inverse-primary);
  display: flex;
  align-items: center;
  gap: 8px;
}

.range-date {
  color: var(--colour-contrast-primary);
  opacity: 0.8;
  font-size: 0.9em;
} 