.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.modal-content {
  background: var(--colour-secondary);
  padding: 48px;
  border-radius: 16px;
  max-width: 900px;
  width: 90%;
  position: relative;
  color: var(--colour-inverse-primary);
  text-align: center;
  box-shadow: 0 24px 48px -12px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(187, 134, 252, 0.1);
}

.modal-close {
  position: absolute;
  top: 24px;
  right: 24px;
  background: transparent;
  border: none;
  color: var(--colour-inverse-primary);
  cursor: pointer;
  padding: 8px;
  line-height: 1;
  transition: all 0.15s ease;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close:hover {
  background: var(--colour-tertiary);
}

.modal-content h2 {
  margin-bottom: 12px;
  color: var(--colour-inverse-primary);
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.modal-content p {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--colour-inverse-primary);
  opacity: 0.8;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin: 0 0 32px 0;
}

.pricing-card {
  background: #1a1a1a;
  padding: 32px;
  border-radius: 12px;
  width: 300px;
  border: 1px solid rgba(187, 134, 252, 0.1);
  transition: all 0.2s ease;
  text-align: left;
}

.pricing-card.featured {
  background: #202020;
  border-color: var(--colour-contrast-primary);
}

.pricing-card:hover {
  border-color: var(--colour-contrast-primary);
  box-shadow: 0 2px 5px -2px rgba(187, 134, 252, 0.25);
  background: #242424;
}

.pricing-card.featured:hover {
  background: #282828;
}

.pricing-card h3 {
  font-size: 20px;
  margin-bottom: 8px;
  color: var(--colour-inverse-primary);
  font-weight: 600;
}

.pricing-card .price {
  font-size: 42px;
  font-weight: 600;
  color: var(--colour-contrast-primary);
  margin-bottom: 24px;
  letter-spacing: -1px;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin: 0 0 32px 0;
}

.pricing-card li {
  margin: 12px 0;
  color: var(--colour-inverse-primary);
  opacity: 0.8;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.pricing-card li::before {
  content: '✓';
  color: var(--colour-contrast-primary);
  font-weight: bold;
  font-size: 14px;
}

.subscribe-button {
  width: 100%;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease;
  border: none;
  background: var(--colour-contrast-primary);
  color: var(--colour-primary);
}

.subscribe-button:hover:not(:disabled) {
  opacity: 0.9;
  transform: translateY(-1px);
}

.subscribe-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.current-plan-button {
  width: 100%;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
  border: 1px solid var(--colour-contrast-primary);
  color: var(--colour-contrast-primary);
  opacity: 0.7;
  cursor: not-allowed;
}

.cancel-button {
  background: transparent;
  color: var(--colour-inverse-primary);
  opacity: 0.7;
  border: none;
  padding: 12px 24px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.15s ease;
  margin-top: 24px;
}

.cancel-button:hover {
  opacity: 1;
  background: var(--colour-tertiary);
  border-radius: 8px;
}

.error-message {
  color: #ff4444;
  background: rgba(255, 68, 68, 0.1);
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  border: 1px solid rgba(255, 68, 68, 0.2);
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.error-message::before {
  content: '!';
  background: rgba(255, 68, 68, 0.2);
  color: #ff4444;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 12px;
}

.cancellation-confirm {
  text-align: center;
  padding: 24px;
  background: rgba(255, 0, 0, 0.05);
  border-radius: 12px;
  margin: 24px 0;
}

.cancellation-confirm h3 {
  color: var(--colour-inverse-primary);
  margin-bottom: 12px;
  font-size: 20px;
}

.cancellation-confirm p {
  color: var(--colour-inverse-secondary);
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 1.5;
}

.cancellation-actions {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.cancel-confirm-button,
.cancel-deny-button {
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease;
  border: none;
}

.cancel-confirm-button {
  background: #ff4444;
  color: white;
}

.cancel-confirm-button:hover:not(:disabled) {
  background: #ff2222;
}

.cancel-deny-button {
  background: var(--colour-tertiary);
  color: var(--colour-inverse-primary);
}

.cancel-deny-button:hover:not(:disabled) {
  background: var(--colour-secondary);
}

.cancel-confirm-button:disabled,
.cancel-deny-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.downgrade-button {
  width: 100%;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.15s ease;
  background: transparent;
  border: 1px solid #ff4444;
  color: #ff4444;
}

.downgrade-button:hover:not(:disabled) {
  background: rgba(255, 68, 68, 0.1);
}

.downgrade-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: var(--colour-inverse-secondary);
  color: var(--colour-inverse-secondary);
} 