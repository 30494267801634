.subscription-sticker {
  position: fixed;
  bottom: 16px;
  left: 16px;
  padding: 6px 12px;
  background: linear-gradient(135deg, 
    rgba(187, 134, 252, 0.2) 0%,
    rgba(187, 134, 252, 0.1) 100%
  );
  border: 1px solid rgba(187, 134, 252, 0.3);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  font-weight: 500;
  font-size: 13px;
  color: var(--colour-inverse-primary);
  display: flex;
  align-items: center;
  gap: 6px;
  backdrop-filter: blur(8px);
  box-shadow: 
    0 2px 10px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(187, 134, 252, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  overflow: hidden;
  width: fit-content;
}

.subscription-sticker:hover {
  transform: translateY(-1px);
  box-shadow: 
    0 4px 20px rgba(187, 134, 252, 0.2),
    0 0 0 1px rgba(187, 134, 252, 0.2),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.subscription-sticker:not(.premium):hover {
  background: linear-gradient(135deg, 
    rgba(187, 134, 252, 0.25) 0%,
    rgba(187, 134, 252, 0.15) 100%
  );
  border-color: rgba(187, 134, 252, 0.4);
}

.subscription-sticker.premium {
  background: linear-gradient(135deg, 
    rgba(187, 134, 252, 0.3) 0%,
    rgba(187, 134, 252, 0.2) 100%
  );
  border-color: rgba(187, 134, 252, 0.5);
}

.subscription-sticker.premium:hover {
  background: linear-gradient(135deg, 
    rgba(187, 134, 252, 0.35) 0%,
    rgba(187, 134, 252, 0.25) 100%
  );
  border-color: rgba(187, 134, 252, 0.6);
}

.subscription-sticker:not(.premium)::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 50%,
    transparent 100%
  );
  border-radius: inherit;
  width: 100%;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.3s ease;
}

.subscription-sticker:not(.premium):hover::before {
  animation: shine 1.5s ease-in-out;
}

@keyframes shine {
  0% {
    opacity: 1;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}

.subscription-sticker .cancellation-pending {
  font-size: 12px;
  opacity: 0.7;
  margin-left: 8px;
  color: #ff4444;
  background: rgba(255, 68, 68, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
} 