.carousel-container {
  width: 55%; /* Adjust the width as necessary */
  margin: 20px auto; /* Center the carousel */
  padding: 20px;
  border-radius: 10px; /* Optional: add rounded corners */
}

.carousel-image {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Optional: add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: add a shadow */
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out; /* Smooth transition */
}

.carousel-image:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  opacity: 0.9; /* Slight transparency on hover */
}

.slick-dots li button:before {
  font-size: 12px;
  color: white; /* Color of the dots */
}

.slick-dots li.slick-active button:before {
  color: white; /* Change active dot color */
}
