#privacy-policy {
  font-family: Arial, sans-serif;
  /* background-color: #f9f9f9; */
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.container {
  padding: 20px;
  /* background: #fff; */
  border-radius: 8px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.sub-title {
  font-size: 18px;
  font-weight: 400;
  color: #777;
  margin-bottom: 20px;
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  color: #444;
  margin: 20px 0 10px;
}

p {
  line-height: 1.6;
  color: #555;
  margin: 10px 0;
}

ul.info-list {
  margin: 15px 0;
  padding-left: 20px;
}

ul.info-list li {
  margin-bottom: 10px;
}

ul.info-list li strong {
  color: #333;
}

.contact-link {
  color: #1a73e8;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}
