/* Styling for NavBar */
nav {
  height: 40px;
  padding: 15px 0;
  /* width: 100vw; */
  width: 100%;

  display: flex;
  justify-content: center; /* Center the NavBar */
  align-items: center;

  position: sticky; 
  /* position: fixed;  */

  top: 0; /* Position at the top */
  left: 0; /* Align to the left edge */
  z-index: 10; /* Ensure it is above the rest of the content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
  /* background-color: #292929; */
  background-color: transparent;

  /* border-bottom: 1px solid rgba(255, 255, 255, 0.386); */
  /* backdrop-filter: blur(10px); */

  backdrop-filter: blur(5px);

  z-index: 1000;
}

nav .logo{
  aspect-ratio: 1/1;
  height: calc(100% - 30px);
  object-fit: contain;

  margin: 0;

  position: absolute;
  left: 0;

  cursor: pointer;
}

/* NavBar container for the links */
.navbar-container {
  height: 100%;
  /* width: 100%;  */
  display: flex;
  /* justify-content: flex-end;  */
  align-items: center;
  
  position: absolute;
  right: 0;

  padding-right: 15px;
  padding-left: 15px;
}

/* Styling for the ul and li elements */
nav ul {
  list-style: none; /* Remove default list style */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust spacing between links */

  height: 100%;
}

nav li {
  /* margin: 0 10px;  */

  height: 100%;

  display: flex;
  align-items: center;
}

nav button {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  
  border-radius: 10px;
  outline: none;
  
  border: 2px solid transparent;
  background: #BB86FC;
  color: black;

  cursor: pointer;
  /* transition: 0.2s ease; */
}

nav button:hover{
  background: transparent;
  color: #BB86FC;
  border: 2px solid #BB86FC;
}

/* Styling for anchor tags inside the NavBar */
nav ul a {
  /* font-weight: bold; */
  font-weight: normal;
  text-decoration: none;
  /* color: white;  */
  /* color: black;  */
  color: inherit; 
  /* font-size: 18px; */
  font-size: 1em;
  font-weight: 700;
  transition: 0.2s ease;
}

nav ul a:hover {
  color: #BB86FC; 
}


nav #profile-icon {
  /* aspect-ratio: 1/1; */
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  /* padding: 5px; */

}

nav #profile-icon img{
  height: 60%;
  border: 1px solid rgba(255, 255, 255, 0.386);
  border-radius: 50%;

  cursor: pointer;
}

nav #profile-icon #profile-dropdown {
  position: absolute;

  /* width: 200px; */
  max-width: 80vw;

  background-color: #121212;
  padding: 10px;

  top: calc(100% - 5px);
  right: 30px;

  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s ease;
  display: none;
}

nav #profile-icon #profile-dropdown.active {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

nav #profile-icon #profile-dropdown button{
  white-space: nowrap;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(255, 255, 255, 0.386);

  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: 0.1s ease;
}

nav #profile-icon #profile-dropdown button:hover{
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}
nav #profile-icon #profile-dropdown button#logout-btn:hover{
  color: rgb(255, 77, 77);
}