.dropdown-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send-title {
  color: var(--colour-inverse-primary);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
}

.dropdown-wrapper {
  position: relative;
  /* background: var(--colour-tertiary); */
  /* border-radius: 10px; */
  /* border: 1px solid rgba(187, 134, 252, 0.2); */
  transition: all 0.3s ease;
  /* min-width: 200px; */
  max-width: 300px;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
}

.dropdown-wrapper:hover {
  border-bottom: 1px solid rgba(255, 255, 255);
  /* box-shadow: 0 0 0 1px rgba(187, 134, 252, 0.1); */
}

.dropdown-wrapper:focus-within {
  /* border-color: var(--colour-primary); */
  /* box-shadow: 0 0 0 2px rgba(187, 134, 252, 0.2); */
}

.dropdown-selection {
  background: transparent !important;
  /* padding: 10px 35px 10px 15px; */
  padding: 5px 35px 5px 5px;
  font-size: 15px;
  color: var(--colour-inverse-primary);
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0.5;
  filter: invert(1);
}

.dropdown-wrapper:hover .dropdown-icon {
  opacity: 1;
}

.dropdown-wrapper:focus-within .dropdown-icon {
  transform: translateY(-50%) rotate(180deg);
  opacity: 1;
}

.dropdown-selection option {
  background: var(--colour-tertiary);
  color: var(--colour-inverse-primary);
  padding: 12px;
  font-size: 14px;
}

/* Style for the selected option */
.dropdown-selection option:checked {
  background: rgba(187, 134, 252, 0.15);
  color: var(--colour-primary);
}

/* Hover effect for options */
.dropdown-selection option:hover {
  /* background: rgba(187, 134, 252, 0.1); */
}
