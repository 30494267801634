/* SaveToCSV Button Styling */
.export-button {
  /* background-color: #bb86fc; Purple background color from the palette */
  background-color: transparent;
  color: #fff; /* White text */
  border: none;
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Padding for spacing */
  /* font-size: 1rem; Font size */
  font-weight: 600; /* Bold text */
  cursor: pointer;

  border: 1px solid #fff; /* White border */
  transition: 0.2s ease; /* Animation */

  opacity: 0.5;
}

/* Hover effect */
.export-button:hover {
  background-color: #9a6fe0; /* Darker purple on hover */
  transform: translateY(-2px); /* Lift effect on hover */
  opacity: 1;
}

/* Active effect */
.export-button:active {
  background-color: #7a54c7; /* Even darker purple on click */
  transform: translateY(0); /* No lift effect on click */
}

/* Additional styles for better spacing */
.export-button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Light shadow on focus */
}
