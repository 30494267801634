.warning-inline {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #292929;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.9em;
  max-width: fit-content;
}

.warning-icon {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.warning-text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  color: #e0e0e0;
}

.warning-range {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: rgba(187, 134, 252, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
}

.warning-date {
  color: #bb86fc;
  font-size: 0.9em;
}

.warning-separator {
  color: #404040;
  margin: 0 4px;
}

.warning-inline.dataset-complete {
  background-color: rgba(76, 175, 80, 0.1);
  border: 1px solid #4CAF50;
}

.warning-inline.dataset-complete .warning-text {
  color: #4CAF50;
  font-weight: 500;
} 