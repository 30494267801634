.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
  background-color: var(
    --colour-primary
  ); /* Use your existing background color */
  padding: 20px;
}

/* Logo styling */
.logo {
  width: 150px; /* Set logo size */
  margin-bottom: 20px; /* Space between the logo and the card */
}

/* Container for the form card */
.auth-card {
  width: 100%;
  /* Set maximum width for card */
  max-width: 500px;
  padding: 2rem; /* Padding inside the card */
  background-color: #292929; /* Use your existing background color */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

/* Title and Subtitle Styles */
.auth-title {
  margin-top: 10px;
  text-align: center;
  font-size: 2.5rem; /* Increase font size */
  font-weight: bold;
  color: var(--colour-inverse-primary); /* White text */
}

.auth-subtitle {
  text-align: center;
  font-size: 1rem; /* Slightly larger font size */
  color: var(--colour-inverse-primary); /* White text */
  margin-top: 0.5rem;
}

/* Link inside subtitle */
.auth-subtitle a {
  color: var(--colour-contrast-primary); /* Blue color for links */
  text-decoration: none;
}

.auth-subtitle a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Error message styling */
.error-message {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
  border: 1px solid #f5c6cb;
  border-radius: 0.375rem;
}

/* Form styling */
.auth-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem; /* Space between form elements */
}

/* Label styling */
.auth-label {
  font-size: 1rem;
  font-weight: 500;
  color: var(--colour-inverse-primary); /* White text */
}

/* Shared Input field styling */
.auth-input {
  margin-top: 5px;
  width: 93.25%; /* Set full width for consistent size */
  padding: 0.75rem 1rem; /* Spacing inside input */
  border: 1px solid #3f3d3d; /* Light gray border */
  border-radius: 0.375rem;
  font-size: 1rem;
  color: var(--colour-inverse-primary); /* White text */
  background-color: #121212; /* Dark background for input fields */
}

/* Input focus state */
.auth-input:focus {
  outline: none;
  border-color: var(--colour-contrast-primary); /* Blue border on focus */
  box-shadow: 0 0 0 1px var(--colour-contrast-primary);
}

/* Submit button styling */
.auth-button {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--colour-contrast-primary); /* Blue background */
  color: var(--colour-inverse-primary); /* White text */
  font-size: 1.2rem; /* Larger font size for the button */
  font-weight: 600;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background transition */
}

/* Button hover state */
.auth-button:hover {
  background-color: #2b6cb0; /* Darker blue on hover */
}

/* Privacy policy link */
.privacy-link {
  margin-top: 20px;
  text-align: center;
  color: var(--colour-inverse-primary); /* White text color */
  font-size: 0.875rem;
}

.privacy-link a {
  color: var(--colour-contrast-primary); /* Blue text for links */
  text-decoration: none;
}

.privacy-link a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .auth-card {
    padding: 2rem; /* Decrease padding for smaller screens */
  }
}

/* Container for the Terms of Service checkbox and link */
.terms-container {
  display: flex;
  align-items: center;
  margin: 15px 0; /* Space between checkbox and other elements */
  gap: 10px; /* Space between elements inside the container */
}

/* Checkbox styling */
.terms-container input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and label */
}

/* Label for Terms of Service */
.terms-label {
  font-size: 0.875rem;
  color: var(--colour-inverse-primary); /* White text */
}

/* Container for the separate Terms of Service view link */
.terms-link-container {
  text-align: left; /* Align link to the left */
  margin: 10px 0; /* Space above and below the link container */
}

/* General styling for Terms of Service links */
.terms-link,
.terms-view-link {
  color: var(--colour-contrast-primary); /* Blue color for links */
  text-decoration: underline;
  cursor: pointer;
  margin-left: -5px; /* Space to the left of the link */
}

/* Hover effect for Terms of Service links */
.terms-link:hover,
.terms-view-link:hover {
  color: #2b6cb0; /* Slightly darker blue on hover */
}

/* Style for terms-link when used without underline */
.terms-link {
  text-decoration: none; /* Remove underline when using .terms-link */
}

.terms-link:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal appears above other elements */
}

/* Modal Content Container */
.modal-content {
  background: #292929; /* Match the auth card background */
  padding: 2rem;
  width: 80%;
  max-width: 700px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1001;
  overflow-y: auto;
  max-height: 80vh; /* Ensure the modal does not exceed the screen height */
}

/* Close Button */
.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--colour-inverse-primary); /* White color for the button */
  font-size: 1.5rem;
  cursor: pointer;
}

.close-button:hover {
  color: #f56565; /* Light red on hover */
}

/* Modal Link Style */
.terms-link {
  color: var(--colour-contrast-primary);
  text-decoration: underline;
  cursor: pointer;
}

.terms-link:hover {
  color: #2b6cb0; /* Darker blue on hover */
}

.google-btn {
  width: 100% !important;
  margin-top: 20px !important;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}
