#Home {
  /* --menu-spacing: 200px; */
  --menu-spacing: 100px;
  --top-spacing: 100px;

  /* --home-padding: 1rem; */
  --home-padding: 0rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100vh - var(--home-padding) * 2);
  width: calc(100vw - var(--home-padding) * 2);
  padding: var(--home-padding);

  background-color: #000;

  color: var(--colour-inverse-primary);

  /* overflow: hidden; */
}

#Home * {
  /* border-style: dotted; */
}

#Home .top {
  width: 100%;
  height: var(--top-spacing);
  position: relative;
}

#Home .top #home-logo {
  position: absolute;
  height: calc(100% - 30px);

  padding: 15px;

  left: 0;

  cursor: pointer;
}

#Home .top #profile-icon {
  position: absolute;
  height: calc(100% - 30px);

  padding: 15px;

  right: 0;
  top: 0;
  cursor: pointer;

  display: flex;
  flex-direction: column;

  gap: 10px;
  z-index: 100;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Home .top #profile-icon #profile-dropdown {
  position: absolute;
  background-color: #2c2c2c;
  padding: 10px;
  top: calc(100% - 5px);
  right: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  transition: 0.3s ease;
  display: none;
}

#Home .top #profile-icon #profile-dropdown.active {
  display: block;
}

#Home .top #profile-icon #profile-dropdown #logout-btn {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--colour-inverse-primary);
  cursor: pointer;
  transition: 0.1s ease;
}

#Home .top #profile-icon #profile-dropdown #logout-btn:hover {
  color: rgb(255, 77, 77);
}

#Home .top #profile-icon img {
  border-radius: 50%;
  height: 100%;
  width: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
  filter: grayscale(1);
}

#Home .content {
  height: 100%;
  width: calc(100% - 20px);
  /* width: 100%; */
  display: flex;

  /* padding: 0 20px 20px 0; */

  overflow: hidden;
}

/* Menu that holds buttons to open dashboard, scraped results, and send email 
*/

#Home .content .menu {
  height: 100%;
  width: var(--menu-spacing);
  min-width: var(--menu-spacing);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  transition: width 0.3s ease;
}

#Home .content .menu.expanded {
  width: 200px;
}

#Home .content .menu button {
  width: 100%;
  height: 100px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--colour-inverse-primary);
  cursor: pointer;
  transition: 0.1s ease;

  display: flex;
  justify-content: center;
  align-items: center;
}

#Home .content .menu button.MenuButton {
  padding: 10px 20px;
  margin-left: 5px;
  border-radius: 10px;
}

#Home .content .menu button.MenuButton:hover {
  transform: translateY(-0.25rem);
  background-color: rgba(255, 255, 255, 0.1);
}

#Home .content .menu button img {
  width: 2rem;
  height: 2rem;
  filter: invert(1);
  flex-shrink: 0;
}

#Home .content .menu button .button-content {
  display: flex;
  align-items: center;
  gap: 12px;
  /* height: 100%; */
}

#Home .content .menu:not(.expanded) button .button-content {
  justify-content: center;
  flex-direction: row;
}

#Home .content .menu.expanded .MenuButton{
  justify-content: flex-start;
}

#Home .content .menu.expanded button .button-content {
  justify-content: flex-start;
  flex-direction: row;
}

#Home .content .menu button .button-label {
  font-size: 0.9rem;
  color: var(--colour-inverse-primary);
  opacity: 0.8;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0;
  transition: max-width 0.3s ease, opacity 0.2s ease;
  display: none;
}

#Home .content .menu.expanded button .button-label {
  max-width: 100px;
  display: block;
}

/* menu active (when the user exapnds the menu) */

/* Container (holds the visual components for home
    * Dashboard
    * Scraped Results
    * Send Email
*/

#Home .content .container {
  flex: 1;
  height: calc(100% - 20px);
  /* background-color: var(--colour-secondary); */
  background-color: transparent;
  border-radius: 20px;
  padding: 20px;
  overflow: hidden;
  transition: margin-left 0.3s ease;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#Home .content .container .menu-item {
  width: 100%;
  height: 100%;

  border-style: dotted;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add specific styling for tables within the container */
#Home .content .container table {
  width: 100%;
  border-collapse: collapse;
}

#Home .content .container table th,
#Home .content .container table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--colour-tertiary);
}

#Home .content .container table th {
  font-weight: 500;
  color: var(--colour-inverse-primary);
  background-color: var(--colour-tertiary);
}

#Home .content .container table td {
  color: var(--colour-inverse-primary);
}

/* Separate toggle button styles completely */
#Home .content .menu .toggle-sidebar {
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--colour-secondary);
  border: none;
  color: var(--colour-inverse-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  z-index: 10;
  padding: 0;
  transition: background-color 0.2s ease;
}

#Home .content .menu .toggle-sidebar:hover {
  background-color: var(--colour-tertiary);
}

/* Update the existing menu button styles */
#Home .content .menu button.MenuButton.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 3px solid #bb86fc;
}

#Home .content .menu button.MenuButton.active .button-content img {
  opacity: 1;
}

#Home .content .menu button.MenuButton.active .button-label {
  color: #fff;
  opacity: 1;
}
