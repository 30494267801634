@font-face {
  font-family: "GTWalsheimPro-Regular";
  src: url("https://cdn.shopify.com/s/files/1/0588/4228/4186/files/GTWalsheimPro-Regular.woff?v=1669253354")
    format("woff");
}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  /* font-family: 'GTWalsheimPro-Regular', sans-serif; */

  font-family: "Inter", sans-serif;

  --colour-primary: #000000;
  --colour-secondary: #121212;
  --colour-tertiary: #292929;

  --colour-inverse-primary: #ffffff;

  --colour-contrast-primary: #bb86fc;
}

:root {
  --colour-primary: #000000; /* Ensure this is set to black */
  --colour-inverse-primary: #ffffff;
  --colour-secondary: #121212;
  --colour-tertiary: #292929;
  --colour-contrast-primary: #bb86fc;
}

::-webkit-scrollbar {
  width: 15px;
  /* width: 100px; */
}

/* Track */
::-webkit-scrollbar-track {
  /* background: transparent; */
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #000000; */
  background: #3e3e3e;

  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
  opacity: 0.8;
}


.fancy-button {
  padding: 6px 12px;
  background: linear-gradient(135deg, 
    rgba(187, 134, 252, 0.2) 0%,
    rgba(187, 134, 252, 0.1) 100%
  );
  border: 1px solid rgba(187, 134, 252, 0.3);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 13px;
  color: var(--colour-inverse-primary);
  display: flex;
  align-items: center;
  gap: 6px;
  backdrop-filter: blur(8px);
  box-shadow: 
    0 2px 10px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(187, 134, 252, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  overflow: hidden;
  width: fit-content;
}

.fancy-button:hover {
  transform: translateY(-1px);
  box-shadow: 
    0 4px 20px rgba(187, 134, 252, 0.2),
    0 0 0 1px rgba(187, 134, 252, 0.2),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}