/* Container for the entire Sign In page */
.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
  background-color: #121212; /* Slightly different background color */
  padding: 50px; /* Increased padding to provide space for the shadow */
  position: relative;
  z-index: 1;
}

/* Background and shadow styling for the container */
.signin-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: var(--colour-primary); /* Use your existing background color */
  box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.05),
    /* Softer inner shadow */ 0 0 20px rgba(255, 255, 255, 0.05); /* Very light outer glow */
}

/* Container for the form card */
.signin-card {
  width: 100%;
  max-width: 500px; /* Set maximum width for card */
  padding: 2rem; /* Padding inside the card */
  background-color: #292929; /* Use your existing background color */
  border-radius: 12px; /* Rounded corners */
  /* Faint shadow for a subtle outline effect */
  box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.1),
    /* Light bottom shadow */ 0px -2px 8px rgba(255, 255, 255, 0.1); /* Light top shadow */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

/* Logo styling */
.logo {
  width: 150px; /* Set logo size */
  margin-bottom: 20px; /* Space between the logo and the card */
}

/* Title and Subtitle Styles */
.signin-title {
  margin-top: 10px;
  text-align: center;
  font-size: 2.5rem; /* Increase font size */
  font-weight: bold;
  color: var(--colour-inverse-primary); /* White text */
}

.signin-subtitle {
  text-align: center;
  font-size: 1rem; /* Slightly larger font size */
  color: var(--colour-inverse-primary); /* White text */
  margin-top: 0.5rem;
}

/* Link inside subtitle */
.signin-subtitle a {
  color: var(--colour-contrast-primary); /* Blue color for links */
  text-decoration: none;
}

.signin-subtitle a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Error message styling */
.error-message {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
  border: 1px solid #f5c6cb;
  border-radius: 0.375rem;
}

/* Form styling */
.signin-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem; /* Space between form elements */
}

/* Label styling */
.signin-label {
  font-size: 1rem;
  font-weight: 500;
  color: var(--colour-inverse-primary); /* White text */
}

/* Input field styling */
.signin-input {
  margin-top: 5px;
  width: 93.25%;
  padding: 0.75rem 1rem; /* Spacing inside input */
  border: 1px solid #3f3d3d; /* Light gray border */
  border-radius: 0.375rem;
  font-size: 1rem;
  color: var(--colour-inverse-primary); /* White text */
  background-color: #121212; /* Dark background for input fields */
}

/* Input focus state */
.signin-input:focus {
  outline: none;
  border-color: var(--colour-contrast-primary); /* Blue border on focus */
  box-shadow: 0 0 0 1px var(--colour-contrast-primary);
}

/* Submit button styling */
.signin-button {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--colour-contrast-primary); /* Blue background */
  color: var(--colour-inverse-primary); /* White text */
  font-size: 1.2rem; /* Larger font size for the button */
  font-weight: 600;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background transition */
}

/* Button hover state */
.signin-button:hover {
  background-color: #2b6cb0; /* Darker blue on hover */
}

/* Privacy policy link */
.privacy-link {
  margin-top: 50px;
  text-align: center;
  color: var(--colour-inverse-primary); /* White text color */
  font-size: 0.875rem;
}

.privacy-link a {
  color: var(--colour-contrast-primary); /* Blue text for links */
  text-decoration: none;
}

.privacy-link a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .signin-card {
    padding: 2rem; /* Decrease padding for smaller screens */
  }
}

.google-btn {
  width: 100% !important;
  margin-top: 20px !important;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.warning-message {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.google-signin-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.google-signin-button:hover {
  background-color: #357ae8;
}
